
import { defineComponent } from 'vue';
import Ipv4CidSubnetCalculator from '@/views/Ipv4CidSubnetCalculator.vue'

export default defineComponent({
  name: 'App',
  components: {
    Ipv4CidSubnetCalculator
  }
});
