import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mx-2" }
const _hoisted_2 = {
  key: 0,
  class: "form-label"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("select", _mergeProps({
      class: "field form-select",
      value: _ctx.modelValue
    }, {
        ..._ctx.$attrs,
        onChange: ($event) => { _ctx.$emit('update:modelValue', $event.target.value) }
      }), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          value: option,
          key: option,
          selected: option === _ctx.modelValue
        }, _toDisplayString(option), 9, _hoisted_4))
      }), 128))
    ], 16, _hoisted_3)
  ]))
}