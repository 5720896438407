import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mx-2 mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "form-label"
}
const _hoisted_3 = ["value", "placeholder"]
const _hoisted_4 = {
  key: 1,
  class: "flex items-center font-medium tracking-wide text-red-500 text-xs ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", _mergeProps(_ctx.$attrs, {
      value: _ctx.modelValue,
      placeholder: _ctx.label,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      class: "form-input field"
    }), null, 16, _hoisted_3),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}